.single-post {

    .top-wrapper {

        h1 {
            font-weight: 700;
        }
    }
}

.top-wrapper {

    .post-meta {
        color: $white;
    }
}

#single {
    @extend %section-padding;

    article {

        h2 {
            font-weight: 400;
            padding: rem(40) 0;
            font-size: rem(26);
        }

        .article-footer {
            @extend %section-padding;
            display: flex;
            align-items: center;

            img {
                border-radius: 50%;
            }

            .author {
                font-size: rem(26);
                font-weight: 600;
                padding-left: rem(15);
            }
        }
    }

    .previous-next {
        padding-left: 0;
        padding-right: 0;
        display: flex;
        flex-direction: row;
        width:100%;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }

        .previous-post {
            @extend %prev-next;
            text-align: right;

            span {

                &:before {
                    content: '<<';
                    font-weight: 600;
                    padding-right: rem(10);
                }
            }

            &.random {

                span {

                    &:before {
                        content: ''!important;
                    }
                }
            }
        }

        .next-post {
            @extend %prev-next;
            text-align: left;
            align-items: flex-start;

            span {

                &:after {
                    content: '>>';
                    font-weight: 600;
                    padding-left: rem(10);
                }
            }
        }

        .random-post {
            @extend %prev-next;
            text-align: left;
            align-items: flex-start;
            padding-left: 0;
            padding-right: 0;

            & .prev {
                @extend %prev-next;
                width: 100%;
                text-align: right;
                align-items: flex-end;
            }

            & .next {
                @extend %prev-next;
                width: 100%;
                text-align: left;
                align-items: flex-start;
            }
        }
    }
}

.single-post {

    .title-wrapper {
        @extend %heading;
        padding-top: rem(100);
        padding-bottom: rem(50);

        h2 {
            font-weight: 700;
            font-size: rem(30);
            margin-bottom: rem(20);

        }

        .separator {
            @extend %separator;
        }
    }
}

.card {
    @extend %card;
}

.comments-form {
    max-width: 85%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: rem(85);

    .commentlist {
        list-style: none;

        .comment-author {
            font-size: rem(14);

            a {
                color: rgba($primary, .7);
                font-size: rem(14);
            }

            img {
                border-radius: 50%;
            }
        }

        .comment-meta {

            a {
                color: rgba($text, .7);
                font-size: rem(14);
            }
        }

        .reply {

            a {
                color: rgba($secondary, .7);
                font-size: rem(14);
            }
        }
    }

    .comment {
        margin-top: rem(40);
    }

    .children {
        list-style: none;
        margin-top: rem(40);
    }
    
    .comment-reply-title, .comment-notes {
        display: none;
    }

    input, textarea {
        margin-top: 0!important;
    }
    
    .comment-form-comment {
        display: flex;
        flex-direction: column;

        label {
            padding-left: rem(15);
            color: rgba($text, .6);
        }
    }
    
    .comment-fields {
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        .field {
            width:31%;

            @include media-breakpoint-down(md) {
                width:100%;
            }

            input {
                width: 100%;
                display: block;
            }

            label {
                color: rgba($text, .6);
                padding-left: rem(15);
            }
        }
    }

    .form-submit {
        display: flex;
        justify-content: flex-end;
        margin-top: rem(40);

        input {
            padding-left: rem(36);
            padding-right: rem(36);
        }
    }

    #wait_approval {
        color: $primary;
        padding-top: rem(50);
    }
}

.pagination {

    .page-numbers {
        list-style: none;
        padding-left: 0;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        font-size: rem(22);

        li {
            padding: rem(10);

            a {
                font-size: rem(22);
            }
        }
    }
}