#contact {
    @extend %section-padding;

    .separator {
        @extend %separator;
    }

    .contact-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            @extend %heading;
        }

        .subtitle {
            @extend %subtitle;
        }

        .contact-form {
            margin-top: rem(40);

            label, input, textarea {
                width: 100%;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }

            label {

                &:last-child {

                    input {
                        margin-top: rem(32);

                        @include media-breakpoint-down(sm) {
                            margin-top: rem(20);
                        }
                    }
                }
            }

            input[type="submit"] {
                float: right;
                margin-top: rem(30);
                width: initial;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }

            .recaptcha-note {
                font-size: rem(14);
                line-height: rem(16);
            }
        }
    }
}