#register {
    @include linear-gradient( $secondary, $primary );
    @extend %section-padding;
    color: $white;

    .register-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .subtitle {

            h2 {
                font-size: rem(24);
                text-transform: uppercase;
                font-weight: 600;
                margin-bottom: rem(40);

                @include media-breakpoint-down(md) {
                    text-align: center;
                }
            }
        }
    }
}