// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

// =============================================================================
// Responsive Font
// =============================================================================

// USE IN MEDIA QUERIES

// $min-font-size - min font size for responsive range
// $max-font-size - max font size for responsive range
// $min-width - min width responsive range
// $max-width - max width responsive range
@mixin responsive-font($min-font-size, $max-font-size, $min-width, $max-width) {
	font-size: calc(#{$min-font-size}px + (#{$max-font-size} - #{$min-font-size}) * ((100vw - #{$min-width}px) / (#{$max-width} - #{$min-width})));
}

%clearfix {
	*zoom: 1;
	
	&:before, &:after {
		content: " ";
		display: table;
	}
	
	&:after {
		clear: both;
	}
}

@mixin ghostVerticalAlign() {
	
	&:before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		height: 100%;
		width: .1px;
	}
}

@mixin truncateText($overflow: ellipsis) {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: $overflow; // values are: clip, ellipsis, or a string
}

$base-font-size: 18;

@function calc-rem($size) {
	$remSize: $size / $base-font-size;
	@return $remSize * 1rem;
}

@function rem($size) {
	$remSize: $size / $base-font-size;
	@return $remSize * 1rem;
}


@mixin arrow-right() {
	content: ' ';
	display: inline-block;
	border: solid $white;
	border-width: 0 1px 1px 0;
	padding: 5px;
	color: $white;
	margin-left: 10px;
	@include transform(rotate(-45deg));
}

@mixin linear-gradient($from, $to) {
	background: $to;
	background: -moz-linear-gradient(left, $from 0%, $to 100%);
	background: -webkit-gradient(linear, left, left, color-stop(0%,$from), color-stop(100%,$to));
	background: -webkit-linear-gradient(left, $from 0%,$to 100%);
	background: -o-linear-gradient(left, $from 0%,$to 100%);
	background: linear-gradient(to right, $from 0%,$to 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$from}, endColorstr=#{$to})
}

@mixin linear-gradient-vertical($from, $to) {
	background: $to;
	background: -moz-linear-gradient(top, $from 0%, $to 100%);
	background: -webkit-gradient(linear, top, bottom, color-stop(0%,$from), color-stop(100%,$to));
	background: -webkit-linear-gradient(top, $from 0%,$to 100%);
	background: -o-linear-gradient(top, $from 0%,$to 100%);
	background: linear-gradient(to bottom, $from 0%,$to 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$from}, endColorstr=#{$to})
}

@mixin transition {
	transition-duration: 3s;
}

@mixin placeholder {
	::-webkit-input-placeholder {@content}
	:-moz-placeholder           {@content}
	::-moz-placeholder          {@content}
	:-ms-input-placeholder      {@content}
}

@mixin placeholder-disappear {
	input:focus::-webkit-input-placeholder {@content}
	input:focus:-moz-placeholder           {@content}
	input:focus::-moz-placeholder          {@content}
	input:focus:-ms-input-placeholder      {@content}
	textarea:focus::-webkit-input-placeholder {@content}
	textarea:focus:-moz-placeholder           {@content}
	textarea:focus::-moz-placeholder          {@content}
	textarea:focus:-ms-input-placeholder      {@content}
}

@include placeholder {
	color: rgba($text, .5);
	font-size: rem(16);
}

@include placeholder-disappear {
	color: transparent;
}
