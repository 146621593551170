$primary: #004494;
$primary-dark: #07274d;
$secondary: #e51a37;
$text: #776e6e;
$text-light: #cccccc;
$light-gray: #e9ecef;
$light-bcg: #f9f9f9;

$base-font: 'Poppins', sans-serif;

$input-btn-padding-y: .9rem;
$input-btn-padding-x: 3.6rem;
$input-btn-padding-y-lg: 1.4rem;
$input-btn-padding-x-lg: 3.6rem;
