body {

    @include media-breakpoint-down(md) {

        .header-wrapper {
            margin-top: 30px;

            .left {
                display: flex;

                img {
                    max-width: rem(160);
                }
            }

            .right {

                .top {
                    display: flex;
                    border-bottom: 2px solid rgba($text, .15);

                    .phone-wrapper {
                        display: flex;

                        .call {
                            display: none;
                        }
                    }
                }

                .page-title {
                    display: none;
                }
            }
        }
    }
}