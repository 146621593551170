#why {
    @extend %section-padding;

    .why-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            
            h2 {
                @extend %heading;
            }
        }

        .subtitle {
            @extend %subtitle;
            padding-bottom: rem(60);
        }

        .slider-wrapper {
            display: flex;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                align-items: center;
            }

            .text {
                width:60%;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }

            .image {
                width: 40%;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }

            h2 {
                color: $secondary;
                font-weight: 600;
                font-size: rem(24);
                width:60%;
                align-self: flex-end;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }

        }

        .slider1, .slider2 {

            .owl-dots {

                .owl-dot {
                    outline: 0;

                    span {
                        width: rem(12);
                        height: rem(12);
                        background-color: $white;
                        border: 1px solid $secondary;
                    }

                    &.active {

                        span {
                            background-color: $secondary;
                        }
                    }
                }
            }

            .owl-nav {

                .owl-prev, .owl-next {

                    &:hover {
                        background-color: transparent;
                    }

                    span {
                        font-size: rem(22);
                        color: $primary;
                    }

                    &:focus {
                        outline: 0;
                        border: none;
                    }
                }
            }
        }

        .slider1 {
            margin-bottom: rem(80);

            .text {
                padding-right: rem(40);

                @include media-breakpoint-down(md) {
                    padding-right: 0;
                }
            }

            .owl-nav {
                position: absolute;
                left:0;
                margin-top: -8px;
            }

            .owl-dots {
                text-align: right;
            }
        }

        .slider2 {

            .slider-wrapper {

                @include media-breakpoint-down(md) {
                    flex-direction: column-reverse;
                }
            }

            .text {
                padding-left: rem(40);
                text-align: right;
                display: flex;
                flex-direction: column;

                @include media-breakpoint-down(md) {
                    padding-left: 0;
                    text-align: left;
                }
            }

            .owl-nav {
                position: absolute;
                right:0;
                margin-top: -8px;
            }

            .owl-dots {
                text-align: left;
            }
        }
    }
}