#page-builder {
    margin-top: rem(85);

    .half-column {
        margin-bottom: rem(85);

        h4 {
            font-size: rem(24);
            font-weight: 400;
            color: $primary;

            strong {
                font-weight: 600;
            }
        }

        h3 {
            font-size: rem(22);
            font-weight: 500;
            color: $primary;
        }
    }

    .one-column {
        margin-bottom: rem(85);
    }

    .two-columns {
        margin-bottom: rem(85);
    }

    .title {
        margin-bottom: rem(45);

        h1, h2, h3, h4, h5, h6 {
            @extend %heading;
        }

        .separator {
            @extend %separator;
        }

        .subtitle {
            @extend %subtitle;
        }
    }

    .cta {
        margin-bottom: rem(85);
        @extend %cta;

        .subtitle {
            font-size: rem(30);
        }

        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }

    .icons {
        margin-bottom: rem(85);

        .icons-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .icon-wrapper {
                display: flex;
                align-items: center;
                margin-bottom: rem(55);

                .icon {
                    display: flex;
                    justify-content: center;

                    img {
                        max-height: rem(60);

                        @include media-breakpoint-down(sm) {
                            min-width: rem(60);
                            margin-bottom: rem(10);
                            margin-right: rem(25);
                        }
                    }
                }

                .icon-text {

                    .icon-title {
                        font-size:rem(24);
                        color: $secondary;
                        font-weight: 600;
                    }

                    .icon-subtitle {
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .steps {
        margin-bottom: rem(85);

        .title {

            h2 {
                font-size: rem(24);
                color: $primary;
                font-weight: 600;
            }
        }

        .icons-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .icon-wrapper {
                display: flex;
                align-items: center;
                align-items: flex-start;

                .icon {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;

                    img {
                        max-width: rem(72);

                        @include media-breakpoint-down(sm) {
                            min-width: rem(72);
                            margin-bottom: rem(10);
                            margin-right: rem(25);
                        }

                    }
                }
            }
        }

        .steps-button-holder {
            @include media-breakpoint-down(sm) {
                margin-left: 0!important;
            }
        }

        .button {
            margin-top: rem(45);

            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }
    }

    ul {
        padding-left: rem(130);
        list-style: none;

        @include media-breakpoint-down(sm) {
            padding-left: rem(30);
        }

        li {
            padding-bottom: rem(45);
            font-weight: 600;

            &:last-child {
                padding-bottom: initial;
            }

            &:before {
                content:'';
                background-image: url("../images/tick.svg");
                display: inline-block;
                width:rem(20);
                height: rem(15);
                margin-right: rem(25);
            }
        }
    }
}