#blog {
    background: $light-bcg;
    padding-bottom: rem(85);

    .title-wrapper {
        padding-top: rem(85);
        padding-bottom: rem(85);

        h2 {
            @extend %heading;
        }

        .subtitle {
            @extend %subtitle;
            font-weight: 600;
        }
    }

    .card {

        @extend %card;
    }

    .container {

        &.archive {
            padding-top: rem(85);
        }
    }
}