.menu-wrapper {
    display: flex;
    justify-content: flex-end;

    .page-title {
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
            color: $primary;
            font-weight: 600;
            text-transform: uppercase;
            font-size: rem(22);
            padding: rem(10) rem(20);
            text-align: center;
        }
    }

    #main-nav {
        margin: 20px 0;

        .menu {
            margin: 0;
            padding: 0;
            list-style: none;
            width: 100%;
            text-align: center;

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                position: absolute;
                left: -999em;
            }

            li {
                display: inline-block;
                position: relative;
                text-align: left;

                a {
                    color: $text;
                    text-transform: uppercase;
                    padding: 10px 30px;
                    font-size: rem(16);
                    font-weight: 500;
                    @include transition;

                    @include media-breakpoint-only(lg) {
                        padding: 10px 35px;
                    }

                    &:hover {
                        color: $secondary;
                    }

                }

                &:last-child {

                    a {
                        padding-right: 0;
                    }
                }

                &:hover {
                    > ul {
                        width: 250px;
                        left: 45px;
                        @include linear-gradient( $primary, $secondary );
                        padding: rem(10);
                        border-radius: rem(7);
                        z-index: 999;

                        @include media-breakpoint-down(md) {
                            display: none;
                        }

                        li {

                            a {
                                color: $white;
                                display: block;
                                padding: 10px 45px;
                                padding-left: 10px;

                                &:hover {
                                    color: $secondary;
                                }
                            }
                        }
                    }
                }

                li {
                    display: block;

                    &:hover {
                        > ul {
                            left: 100%;
                            top: 0;
                        }
                    }
                }

                &.current-menu-item {

                    a {
                        color: $secondary;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    #main-nav {

        .mobile-logo {
            display: none;
        }

        .mobile-logo-subtitle {
            display: none;
        }

        @include media-breakpoint-down(md) {

            ul.menu {
                display: none;
                position: fixed;
                top: 0;
                left: 0;
                padding: 20px 0;
                z-index: 50;
                height: 100vh!important;
                @include linear-gradient-vertical( $primary, $secondary );

                li {
                    width: 100%;
                    text-align: center;

                    a {
                        color: $white;
                        padding: 0;
                        font-size: rem(18);
                    }
                }

                .mobile-logo {
                    display: block;

                    img {
                        max-width: rem(170);
                        margin-top: rem(50);
                    }
                }

                .mobile-logo-subtitle {
                    display: block;
                    color: $white;
                    margin-bottom: rem(50);
                    font-size: rem(16);
                }
            }
        }

        @include media-breakpoint-down(md) {

            ul.menu {
                display: none;
                height: 100%;
                
                &.active {
                    display: flex!important;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                }
                
                li {
                    
                    & > ul {
                        display: block;
                        position: relative;
                        left:initial;

                        a {
                            color: rgba($white, .5);
                        }
                    }
                }
            }
        }
    }

    .toggle-nav {
        position: fixed;
        top: 38px;
        right: 25px;
        display: none;
        z-index: 100;

        .bars {
            display: flex;
            flex-direction: column;

            div {
                display: inline-block;
                height: 2px;
                width: 16px;
                margin-bottom: 4px;
                background-color: $primary;

                &:last-child {
                    width: 11px;
                    margin-right: auto;
                }
            }
        }

        .fa-times {
            font-size: 20px;
        }

        &.active {
            color: $white;
            top: 31px;
        }

        @include media-breakpoint-down(md) {
            display: inline-block;
        }
    }
}