.btn {
    font-size: rem(24);
    font-weight: 500;
    border-radius: rem(7);
    padding: rem(15) rem(40);

    @include media-breakpoint-down(sm) {
        font-size: rem(20);
        padding: rem(10) rem(20);
    }

    &:active:focus {
        border: none;
        outline: none;
        box-shadow: none ;
    }

    &:active {
        border: none;
        outline: none;
        box-shadow: none ;
    }

    &:focus {
        border: none;
        outline: none;
        box-shadow: none;
    }
}

.btn-lg {
    font-weight: 500;
    font-size: rem(24);
    border-radius: rem(7);
    padding: rem(15) rem(40);

    @include media-breakpoint-down(sm) {
        font-size: rem(24);
        padding: rem(10) rem(20);
    }
}