#not-found {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    @extend %heading;
  }

  .subtitle {
    @extend %subtitle;
  }

  .button {
    margin-top: rem(45);
    display: flex;
    justify-content: center;
  }
}