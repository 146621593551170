%separator {
    display: block;
    width: rem(62);
    height: rem(4);
    background-color: $secondary;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(20);
    margin-bottom: rem(30);

    &:before {
        content: '';
        display: inline-block;
        width: rem(80);
        height: rem(2);
        background-color: $primary;
        position: absolute;
        top: rem(1);
        left: rem(-80);
    }

    &:after {
        content: '';
        display: inline-block;
        width: rem(80);
        height: rem(2);
        background-color: $primary;
        position: absolute;
        top: rem(1);
        right: rem(-80);
    }
}

%section-padding {
    padding: rem(85) 0;
}

%heading {
    font-size: rem(30);
    color: $primary;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: rem(10);
}

%subtitle {
    font-size: rem(20);
    text-align: center;
}

%cta {
    @include linear-gradient($secondary, $primary);
    @extend %section-padding;

    .col {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .subtitle {
        font-size: rem(24);
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: rem(40);
        color: $white;
    }
}

%placeholder {
    @include linear-gradient(rgba($secondary, .9), rgba($primary, .9));
    display: block;
    height: 360px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

%prev-next {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-height: rem(430);
    justify-content: center;
    height: 100%;
    color: $white;
    position: relative;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    background-position: center;
    padding-left: rem(100);
    padding-right: rem(100);

    @include media-breakpoint-down(sm) {
        width: 100%;
        padding-left: rem(20);
        padding-right: rem(20);
    }

    span {
        color: $white;
        font-size: rem(16);
        z-index: 20;
    }

    a {
        font-size: rem(30);
        font-weight: 700;
        color: $white;
        z-index: 20;
    }

    .overlay {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 10;
        background-color: rgba($primary-dark, .8);
        display: none;
    }

    &.no-thumb {
        background-color: rgba($text, .5);
    }
}

%card {
    margin-bottom: rem(30);
    box-shadow: 0px 2px 5px 1px rgba($black, .0);

    &:hover {
        box-shadow: 0px 2px 5px 1px rgba($black, .1);
    }

    .placeholder {
        @extend %placeholder;
    }

    .img-wrapper {
        max-height: 360px;
        position: relative;
        overflow: hidden;

        .placeholder {
            @extend %placeholder;
            position: absolute;
            top: 0;
            left: 0;
            display: none;

            @include media-breakpoint-down(md) {
                display: none!important;
            }
        }
    }

    .card-body {

        .post-category {
            font-size: rem(14);
            font-weight: 600;
            color: $secondary;
            padding-bottom: rem(20);
            display: block;
        }

        .card-title {
            font-size: rem(24);
            font-weight: 600;
            color: $primary;
        }

        .footer {
            display: flex;

            .author-thumb {

                img {
                    border-radius: 50%;
                }
            }

            .post-meta {
                display: flex;
                flex-direction: column;
                padding-left: rem(15);

                .author {
                    font-size: rem(13);
                    font-weight: 600;
                }

                .date {
                    font-size: rem(12);
                }
            }
        }
    }
}
