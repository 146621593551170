#how {
    @extend %section-padding;
    padding-bottom: rem(40);

    .how-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            @extend %heading;
        }

        .subtitle {
            @extend %subtitle;
        }
    }
}

#how-movie {
    @include linear-gradient($primary, $secondary);
    text-align: center;
    position: relative;

    .embed-container {
        margin-bottom: 0;
    }

    .overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        @include linear-gradient(rgba($primary, .9), rgba($secondary, .9));

        .play-button {
            width:rem(96);
            height:rem(96);
            z-index: 30;
            cursor: pointer;
        }
    }
}