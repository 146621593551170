#top {
    color: $white;

    .container-fluid {
        height: 100vh;
        display: flex;
        align-items: center;
        background-size: cover!important;

        @include media-breakpoint-down(sm) {
            height: auto;
            padding: rem(55) 0;
        }
    }

    h1 {
        font-size:rem(30);
        text-transform: uppercase;
        font-weight: 600;

        @include media-breakpoint-up(md) {
            margin-top: rem(-50);

        }
    }

    p {
        font-size:rem(30);

        strong {
            font-weight: 500;
        }
    }
}