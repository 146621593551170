.social-wrapper {
    padding: 0 50px;
    display: flex;

    @include media-breakpoint-down(md) {
        display: none;
    }

    .social {
        padding: 0 10px;

        &:first-child {

            img {
                height: 15px;
            }
        }

        img {
            height: 20px;
        }
    }
}

.phone-wrapper {
    display: flex;
    flex-direction: row;

    @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: center;
        display: none;
    }

    .call {
        padding: 0 12px;
        color: $primary;
        text-transform: uppercase;
        font-size: rem(13);
        display: flex;
        align-items: center;
        padding-top: rem(2);

        @include media-breakpoint-down(md) {
            align-items: center;
            padding: 10px 0;
        }

        &:before {
            content: '';
            background-image: url( '../images/mobile_menu.svg' );
            display: inline-block;
            height: 24.5px;
            width: 16px;
            margin-right: 10px;
        }
    }

    .phone {
        font-weight: 600;

        &:last-child:before {
            content: '';
            display: inline-block;
            width: 2px;
            height: 13px;
            background-color: $primary;
            margin: 0 10px;
        }
    }
}