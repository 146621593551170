.rozliczenie-personalne {
    h2 {
        font-size: rem(24);
        font-weight: bold;
        line-height: 1.5;
    }

    #page-builder {

        .title {
            margin-bottom: 1.66667rem;
        }

        .steps {
            .title h2 {
                text-align: left;
                text-transform: initial;
            }

            .step {
                margin-bottom: rem(30);

                .icon-text {
                    min-height: rem(72);
                    position: relative;

                    p {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }

        .layerContent {
            margin-bottom: 0;
        }

        .utr-content {
            margin-right: rem(80);
        }



        @include media-breakpoint-up(md) {
            .custom-container .row {
                flex-wrap: nowrap;
            }

            .custom-width-left {
                flex: 0 0 40%;
                max-width: 40%;
            }

            .custom-width-right {
                flex: 0 0 66%;
                max-width: 66%;
            }
        }

        @media only screen and (max-width: 1225px) {
            .custom-width-right {
                flex: 0 0 60%;
                max-width: 60%;
            }
        }
        
        @include media-breakpoint-down(sm) {
            .custom-width-right {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        .two-columns {
            .title {
                h2 {
                    text-align: left;
                    text-transform: initial;
                    font-size: rem(24);
                }
            }

            ul {
                padding-left: 0;
                margin-bottom: 40px;

                &.circle-tick {
    
                    li::before {
                        background-image: url('../images/tick-circle.svg');
                        background-repeat: no-repeat;
                        height: 1.5rem;
                        width: 1.5rem;
                        vertical-align: middle;
                        margin-right: 1.4rem;

                        @include media-breakpoint-down(md) {
                            height: 2rem;
                            width: 2rem;
                        }
                    }

                    li {
                        list-style-type: none;
                        text-indent: rem(-52);
                        margin-left: rem(52);
                        padding-bottom: 10px;
                        min-height: 100px;

                        @include media-breakpoint-down(md) {
                            text-indent: rem(-60);
                            margin-left: rem(60);
                        }

                        @include media-breakpoint-down(sm) {
                            text-indent: -44px;
                            margin-left: 42px;
                            min-height: 60px;
                        }
                    }
                }

                &:nth-of-type(2) {
                    padding-left: 7.2rem;

                    li::marker {
                        font-size: 1rem;
                    }
                }
                
                li::before {
                    background-image: none;
                    margin-right: 0;
                }

                li::marker {
                    color: $primary;
                    font-size: 1.2rem;
                }

                li {
                    list-style: disc inside none;
                    padding-bottom: 5px;
                }
            }

            .layerContent {
                margin-bottom: 1.6rem;
                padding-left: 7.2rem;
            }
        }

        .no-bottom {
            margin-bottom: rem(5);
        }

        @include media-breakpoint-down(sm) {
            div:nth-child(6) {
                margin-bottom: 40px;
            }
    
            div:nth-child(7) {
                .layerContent {
                    margin-top: 40px;
                    margin-bottom: 40px;
                }
            }
        }


        .apostille {
            padding-left: rem(55);
            line-height: 1.5;

            @include media-breakpoint-down(sm) {
                padding-left: 0;
            }
        }

        .circle-tick {

            padding-left: 0;

            li::before {
                background-image: url('../images/tick-circle.svg');
                background-repeat: no-repeat;
                height: 1.5rem;
                width: 1.5rem;
                vertical-align: middle;
                margin-right: 1.4rem;

                @include media-breakpoint-down(md) {
                    height: 2rem;
                    width: 2rem;
                }
            }

            li {
                list-style-type: none;

                @include media-breakpoint-down(sm) {
                    text-indent: -45px;
                    margin-left: 45px;
                }
            }
        }

        h2.paye {
            margin-bottom: 0;
        }

        .paye {
            padding-left: 2.6rem;

            @include media-breakpoint-down(sm) {
                padding-left: 0;
            }
        }

        .companies {
            padding-left: 0;

            li::before {
                background-image: none;
                margin-right: 0;
            }

            li::marker {
                color: $primary;
                font-size: 1.2rem;
            }

            li {
                list-style: disc inside none;
            }
        }

        h4.layerContent {
            font-size: 1.125rem;
            font-weight: bold;
            line-height: 1.5;
            margin-left: rem(40);

            @include media-breakpoint-down(sm) {
                font-size: 1.025rem;
                text-align: center;
                margin-left: 0;
            }
        }

        .contact-btn-container {
            margin-top: rem(100);
            text-align: center;

            @include media-breakpoint-up(md) {
                margin-left: rem(150);
                text-align: left;
            }

            @include media-breakpoint-down(sm) {
                margin-top: rem(55);
            }
        }
    }
}