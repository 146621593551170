input[type="text"], input[type="email"], input[type="phone"], textarea {
  border: 2px solid $primary;
  border-radius: rem(7);
  padding: rem(25);
  outline: 0;
  margin-top: rem(20);

  @include media-breakpoint-down(sm) {
    padding: rem(15);
  }
}

