// -------------------------GLOBAL
@import "variables";
@import "mixins";
@import "fonts";
@import "./../../node_modules/bootstrap/scss/bootstrap";
@import "utilities";
@import "buttons";

// -------------------------LAYOUT
@import "layout/header";
@import "layout/footer";
@import "layout/page";
@import "layout/sidebar";

// -------------------------PARTIALS
@import "partials/home-top";
@import "partials/home-consult";
@import "partials/home-how";
@import "partials/home-why";
@import "partials/home-register";
@import "partials/home-firms";
@import "partials/home-contact";

// -------------------------PAGES
@import "pages/front";
@import "pages/contact";
@import "pages/blog";
@import "pages/single";
@import "pages/404";
@import "pages/faq";
@import "pages/rozliczenia";

// -------------------------MODULES
@import "modules/header-top";
@import "modules/header-menu";
@import "modules/contact-forms";
@import "modules/page-builder";

html,
body {
  min-height: 100vh;
  font-size: 18px;
  line-height: 32px;
  color: $text;
  font-family: $base-font;
  font-weight: 400;
  overflow-x: hidden;

  @include media-breakpoint-down(sm) {
    font-size: 13px;
  }
}

.site-content {
  flex: 1;
}

a {
  @include transition;
}

a:hover {
  color: $secondary;
  text-decoration: none;
  @include transition;
}

.separator {
  @extend %separator;
}

.with-separator {
  position: relative;
  margin-top: rem(5);

  &:before {
    background-color: $primary;
    bottom: -7px;
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    width: 100%;
  }

  &:after {
    border-left: rem(60) solid $red;
    bottom: -8px;
    content: '';
    display: block;
    height: 4px;
    max-width: rem(60);
    position: absolute;
  }
}

h1, h2, h3, h4, h5, h6, p, ul {
  margin-bottom: rem(30);
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  margin-bottom: 30px;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

img,
picture {
  margin: 0;
  max-width: 100%;
  height: auto;
}

.aligncenter, div.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.alignleft {
  float: left;
  padding-right: 20px;
}
.alignright {
  float: right;
  padding-left: 20px;
}

@include media-breakpoint-down(md) {
  .row {
    padding: 0 rem(15);
  }
}


