#consult {
    background-color: $primary;
    padding-top: 40px;
    padding-bottom: 50px;

    .consult-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $white;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: center;
        }

        .image {
            background: $white;
            padding: rem(5);
            max-width: rem(160);
        }

        .text {
            display: flex;
            flex-direction: column;
            margin-top: rem(30);

            @include media-breakpoint-only(lg) {
                padding:rem(30);
            }

            @include media-breakpoint-down(md) {
                align-items: center;
            }

            p {
                font-size: rem(30);

                @include media-breakpoint-down(md) {
                    text-align: center;
                }
            }

            .name {
                font-size: rem(22);
            }

            .position {
                font-size: rem(20);
            }
        }

        .button {
            margin-top: rem(-70);

            @include media-breakpoint-down(md) {
                align-self: center;
                margin-top: rem(30);
            }
        }
    }
}