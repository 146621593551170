#firms {
  @extend %section-padding;
  background-color: $light-gray;

  .firms-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .subtitle {
      @extend %heading;
      color: $text;
      margin-bottom: rem(40);
      font-weight: 600;
    }

    .firms {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        text-align: center;

        img {
          margin-bottom: rem(20);
        }
      }

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: center;

        img {
          width: auto;
        }
      }
    }
  }
}