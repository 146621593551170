.header-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;

    @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
        margin-top: 0;
    }

    .left {

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .top {
        display: flex;
        justify-content: flex-end;
        border-bottom: 2px solid rgba($text, .15);
        padding: 10px 0;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: center;
            border: none;
            display: none;
        }

        .right {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }
}

header {

    @include media-breakpoint-up(lg) {

        &.fix {
            transition-duration: 300ms;
            position: fixed;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            background: rgba($white, .95);
            z-index: 999;

            .header-wrapper {
                margin: rem(5) 0;

                .custom-logo {
                    height: rem(70);
                    width: auto;
                    transform-origin: left;
                    transition-duration: 300ms;
                }

                .top {
                    display: none;
                }
            }
        }
    }
}

header {

    #header {

        &.container {

            @media only screen and (min-width:576px) and (max-width: 1024px) {
                max-width: 100%!important;
            }
        }
    }
}