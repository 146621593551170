.sidebar {

  .widget {
    padding-bottom: rem(45);

    &:last-child {
      padding-bottom: rem(85);
    }
  }

  .widget-title {
    @extend %heading;
    font-size: rem(24);
  }

  .separator {
    margin-top: rem(10);
  }

  .rpwe-ul {
    list-style: none;
    padding-left: 0;

    li {
      border: 1px solid rgba($primary, .4);
      margin-bottom: rem(15);
      padding: rem(20);
      position: relative;

      .rpwe-title {
        font-size: rem(16);
        margin-bottom: 0;

        a {
          color: $text;
        }
      }

      .rpwe-time {
        font-size: rem(12);
      }
    }
  }

  .recent-posts {
    list-style: none;
    padding-left: 0;
    padding-bottom: rem(45);

    .recent-post {
      border: 1px solid rgba($primary, .4);
      margin-bottom: rem(15);
      padding: rem(20);
      background-size: cover !important;
      min-height: rem(160);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .recent-title {

        h4 {
          font-size: rem(16);
          margin-bottom: 0;
          color: $white;
        }
      }

      .recent-date {
        font-size: rem(12);
        color: $white;
      }

      &.no-img {

        display: block;
        min-height: auto;

        .recent-title {

          h4 {
            color: $text;
          }
        }

        .recent-date {
          color: $text;
        }
      }
    }
  }

  .tagcloud {

    a {
      display: inline-block;
      @include linear-gradient(rgba($primary, .6), rgba($secondary, .6));
      padding: rem(10) rem(20);
      margin-right: rem(7);
      margin-bottom: rem(7);
      color: $white;
      font-size: rem(14) !important;
      font-weight: 600;

      &:hover {
        @include linear-gradient(rgba($primary, 1), rgba($secondary, 1));
      }
    }
  }

  .widget_categories {

    ul {
      list-style: none;
      padding-left: 0;
      display: flex;
      flex-wrap: wrap;

      li {

        a {
          display: inline-block;
          @include linear-gradient(rgba($primary, .6), rgba($secondary, .6));
          padding: rem(10) rem(20);
          margin-right: rem(7);
          margin-bottom: rem(7);
          color: $white;
          font-size: rem(14) !important;
          font-weight: 600;

          &:hover {
            @include linear-gradient(rgba($primary, 1), rgba($secondary, 1));
          }
        }
      }
    }
  }

  #searchsubmit {
    background-color: $primary;
    color: $white;
    margin-top: rem(20);
    outline: 0;
    border: none;
    font-size: rem(24);
    font-weight: 500;
    border-radius: rem(7);
    padding: rem(10);
    cursor: pointer;
  }

  .custom-html-widget {
    margin-bottom: rem(75);

    .btn {
      border: 0;
      font-size: rem(20);
      display: inline-block;
      padding: rem(10) rem(30);
    }
  }
}