.page-template-page-faq {

    .faq {
        padding-top: rem(120);
        padding-bottom: rem(100);
    }

    h2 {
        font-size: rem(24);
        font-weight: bold;
        margin-bottom: rem(20);
    }

    .btn-link {
        font-size: rem(24);
        font-weight: 600;
        padding: rem(17) 15px rem(17) 40px;
        position: relative;
        text-align: left;
        text-decoration: none;
        white-space: normal;

        .icon {
            background-image: linear-gradient($secondary, $primary);
            display: flex;
            width: 24px;
            height: 24px;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: rem(22);
            lefT: 0;

            &:before {
                content: '\f067';
                font-family: 'Font Awesome 5 Free';
                font-size: rem(10);
                color: $white;
            }

            &:after {
                content: '';
                width: 0;
                height: 0;
                border-top: 5px solid transparent;
                border-left: 5px solid $primary;
                border-bottom: 5px solid transparent;
                position: absolute;
                left: 100%;
            }
        }

        &.collapsed {

            .icon {

                &:before {
                    content: '\f128';
                }

                &:after {
                    display: none;
                }
            }
        }
    }

    .accordion {

        .card {

            &:first-of-type {
                border-bottom: 1px solid rgba($primary, .5) !important;
            }

            &:hover {
                box-shadow: none;
            }
        }
    }

    .card {
        border-top: none;
        border-right: none;
        border-bottom: 1px solid rgba($primary, .5) !important;
        border-left: none;
    }

    .card-header {
        background-color: $white;
        border: none;
        padding: 0;
    }

    .card-body {
        padding: 0 15px 35px 40px;
    }

    .tags {

        >h2 {

            &:before {
                @include media-breakpoint-up(md) {
                    right: 0;
                    left: 0;
                    max-width: 215px;
                    margin: 0 auto;
                }
            }

            &:after {
                @include media-breakpoint-up(md) {
                    right: 0;
                    left: 0;
                    margin: 0 auto;
                    transform: translateX(-100%);
                }
            }
        }
    }
}