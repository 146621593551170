.page-top {
    background-size: cover!important;
    background-repeat: no-repeat!important;
    background-position: center!important;

    .top-wrapper {
        height: 50vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        @include media-breakpoint-down(sm) {
            height: auto;
            min-height: rem(300);
            padding: rem(45) 0;
        }

        h1 {
            color: $white;
            font-size: rem(30);
            font-weight: 400;

            strong {
                font-weight: 600;
            }

            span {
                font-weight: 400;
                padding-left: rem(15);
            }
        }
    }
}

#page {

    &.default {

        .page-wrapper {
            @extend %section-padding;
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}