footer {
    @include linear-gradient( $primary, $secondary );
    padding-top: rem(80);
    padding-bottom: rem(35);
    color: $white;

    @include media-breakpoint-down(md) {
        padding-bottom: 35px;
    }

    .footer-blocks {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex-shrink: 0;

        .contacts {
            display: flex;
            flex-direction: column;

            .footer-heading {

                @include media-breakpoint-down(md) {
                    order: 2;
                }

                h4 {
                    font-size: rem(18);
                    font-weight: 600;
                    text-transform: uppercase;
                    margin-bottom: rem(10);

                    @include media-breakpoint-down(md) {
                        font-size: rem(22);
                        text-align: center;
                        margin-top: rem(10);
                        margin-bottom: rem(20);
                    }
                }
            }

            .contact-boxes {
                display: flex;
                justify-content: space-between;
                width: 100%;
                flex-shrink: 0;

                @include media-breakpoint-down(md) {
                    order:3;
                    flex-direction: column;
                }

                .contact-box {
                    display: flex;
                    width: 100%;

                    &.address {
                        justify-content: flex-start;
                    }

                    &.phones {
                        justify-content: center;
                    }

                    &.email {
                        justify-content: flex-end;
                        user-select: none;
                    }

                    @include media-breakpoint-down(md) {
                        flex-direction: column;
                        align-items: center;
                    }

                    img {
                        max-height: rem(40);
                        margin-top: rem(-2);

                        @include media-breakpoint-down(md) {
                            display: none;
                        }

                        @include media-breakpoint-down(md) {
                            max-height: rem(40);
                            padding-bottom: rem(10);
                        }
                    }

                    .box-inner {
                        display: flex;
                        flex-direction: column;
                        padding-left: rem(10);

                        @include media-breakpoint-down(md) {
                            text-align: center;
                        }

                        .heading {
                            color: $secondary;
                            font-weight: 700;
                            font-size: rem(24);
                        }

                        .text {
                            font-size: rem(14);
                            font-weight: 500;
                            color: rgba($white, .7);

                            p {
                                margin-bottom: 0;
                                font-weight: 600;

                                @include media-breakpoint-down(md) {
                                    line-height: rem(30);
                                    margin-bottom: rem(15);
                                    color: rgba($white, 1);
                                }
                            }

                            a {
                                color: rgba($white, .7);
                                
                                @include media-breakpoint-down(md) {
                                    color: rgba($white, 1);
                                }
                            }
                        }
                    }
                }
            }

            .social-wrapper {
                padding: 0;
                display: flex;
                align-items: center;
                margin-top: auto;
                align-self: flex-end;
                margin-top: rem(-28);

                @include media-breakpoint-down(md) {
                    order:1;
                    align-self: center;
                }

                .social {
                    display: flex;
                    align-items: center;

                    svg {
                        fill: $white;
                        height: 15px;

                        path {
                            fill: $white;
                        }
                    }

                    &:last-child {

                        svg {
                            height: 18px;
                        }
                    }
                }
            }
        }
    }

    .regulations-menu {
        display: flex;
        justify-content: center;
        min-height: rem(110);
        align-items: flex-end;

        @media only screen and (max-width: 355px) {
            min-height: rem(300);
        }

        .menu {
            display: flex;
            padding-left: 0;
            list-style: none;
            margin-bottom: 0;

            @media only screen and (max-width: 355px) {
                flex-direction: column;
            }

            li {
                padding: 0 rem(30);

                @include media-breakpoint-down(md) {
                    padding: 0 rem(15);
                    line-height: rem(30);
                }

                @media only screen and (max-width: 355px) {
                    text-align: center;
                }

                a {
                    color: rgba($white, .7);
                    font-size: rem(14);
                    font-weight: 600;

                    &:hover {
                        color: rgba($white, 1);
                    }
                }
            }
        }
    }
}

.copyright-wrapper {
    padding: rem(15);
    text-align: center;
    font-size:rem(14);
    color: $primary;

    @include media-breakpoint-down(sm) {
        font-size:rem(10);
        padding: 15px 0;
    }
}

#cookie-notice {
    @include linear-gradient($secondary, $primary);

    .cookie-notice-container {
        font-size: rem(14);
        padding:rem(10);
        line-height: rem(33);

        .btn-secondary {
            padding: rem(5) rem(20);
            font-size: rem(14);
        }
    }
}

.grecaptcha-badge {
    display: none;
}