#contact-page {

    .separator {
        @extend %separator;
    }

    .contact-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: rem(85);

        h3 {
            @extend %heading;
        }

        .subtitle {
            @extend %subtitle;
        }

        .contact-form {
            margin: rem(40) auto;

            label, input, textarea {
                width: 100%;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }

            textarea {
                padding-bottom: rem(32);
            }

            input[type="submit"] {
                float: right;
                margin-top: rem(30);
                width: initial;

                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }

            .recaptcha-note {
                font-size: rem(14);
                line-height: rem(16);
            }
        }
    }

    .contact-middle {
        width: 100%;
        padding: rem(50) 0 0;

        @include media-breakpoint-up(md) {
            padding: rem(85) 0 0;
        }
    }

    .contact-box {
        display: flex;
        justify-content: center;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            align-items: center;
        }

        img {
            max-height: rem(30);
            max-width: rem(40);

            @include media-breakpoint-down(sm) {
                max-height: rem(40);
                padding-bottom: rem(10);
            }
        }

        .box-inner {
            display: flex;
            flex-direction: column;
            padding-left: rem(10);

            @include media-breakpoint-down(sm) {
                text-align: center;
            }

            .heading {
                color: $secondary;
                font-weight: 700;
                font-size: rem(24);
            }

            .text {
                font-weight: 600;
            }

            &.email {

                .text {
                    user-select: none;
                }
            }
        }
    }

    .map {
        padding-left: 0;
        padding-right: 0;
        position: relative;

        .acf-map {
            width: 100%;
            height: rem(400);
        }

        .map-overlay {
            @include linear-gradient(rgba($secondary, .3), rgba($primary, .3));
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: rem(400);
            z-index: 9;
        }
    }
}